// Colors
$background-color: #c0c0c0e6;
$background-color-alt: #fffaf9;
$homepage-color: #404757;
$intro-back: #fff9f9;
$global-text: #404757;
$accent-background: #ee795a;
// $accent-color: #ef795b;
$accent-color: #f1756d;
$accent-color-light: #feece7;
$gray-text: #5c5c5c;
$lighter-element: #a8abb3;
$table-text: #7d828d;
$border-color: #5461a02b;
$global-shadow-color: #98989847;
$light-text: #767676;
$error-color: #f07676;
$global-shadow: 1px 1px 4px
  lighten(
    $color: $global-shadow-color,
    $amount: 10,
  );
$global-shadow-alt: 0px 10px 48px rgba(37, 96, 150, 0.13);

// Multiselect
$multiselect-back: white;
$multiselect-front: #595454;
$dark-placeholder: #a8a8a8;
$primary-color: $accent-color;
$light-border: #d3d3d321;

// Sizes
$full-nav-height: 100px;
$mob-nav-height: 30px;
$mob-nav-padding-y: 10px;
$mob-nav-padding-x: 20px;

// Elements
$button-border-radius-mini: 4.5px;
$button-border-radius: 42px;
