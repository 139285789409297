button,
.btn,
input,
textarea {
  outline: none;
}

button,
.btn {
  $border-color: rgba(128, 128, 128, 0.2);
  $btn-back: white;
  padding: 7px 15px;
  background-color: $btn-back;
  border-radius: $button-border-radius;
  border: 1px solid;
  border-color: $border-color;
  color: $accent-color;
  font-weight: 600;
  transition: all 0.2s ease;
  text-decoration: none;
  font-family: 'Poppins';
  cursor: pointer;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    font-weight: bold;
    font-size: 90%;
    text-transform: uppercase;

    i {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @media screen and (min-width: 768px) {
    font-size: 100%;
    padding: 13px 22px;

    > span {
      font-size: 0.85rem;
    }
  }

  &.acc.alt {
    background-color: $accent-color-light;
    color: $accent-color;
  }

  &.mini {
    padding: 5px 10px;
  }

  &.long {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.acc {
    background-color: $accent-color;
    color: white;
    border-color: transparent;
  }

  &.trans {
    background-color: transparent;
    border-color: transparent;

    &:hover {
      border-color: $border-color;
    }
  }

  &:hover:not(:disabled) {
    box-shadow: $global-shadow;
    background-color: darken($color: $btn-back, $amount: 4);

    &.acc {
      background-color: darken($color: $accent-color, $amount: 10);

      &.alt {
        color: white;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

input,
textarea {
  $border: 1px solid transparent;
  padding: 6px 10px 4px;
  background-color: white;
  border-radius: 5px;
  border: $border;
  box-shadow: $global-shadow;
  color: $gray-text;
  font-family: 'Poppins';
  // transition: all .2s ease;

  &.alt {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;

    // &::placeholder {
    //   text-align: center;
    // }
  }

  &.max-w {
    width: 100%;
  }

  &::placeholder {
    color: transparent;
    // color: $dark-placeholder;
  }

  &.accent {
    color: $accent-color;
  }

  &:not(:placeholder-shown):invalid:focus {
    // Not empty, but invalid > focused
    background-color: lighten($color: $error-color, $amount: 25);
  }

  &:disabled {
    background-color: darken($color: rgba(0, 0, 0, 0.03), $amount: 10);
  }
}

.input-group {
  position: relative;
  padding-top: 20px;
  width: 100%;

  label {
    position: absolute;
    top: 0;
    left: 5px;
    font-size: 0.65rem;
    color: $dark-placeholder;
  }

  @media screen and (min-width: 768px) {
    width: auto;
  }
}

.fa {
  @media screen and (max-width: 768px) {
    // Only mobile
    font-size: 1rem;
  }
}

.close-btn {
  color: $lighter-element;
  cursor: pointer;
}

// Multiselect component
.multiselect-view {
  color: $gray-text;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.061);
  box-shadow: $global-shadow;
  // padding: 1.5px 0;
  padding: 0;
  border: unset;

  .multiselect--active {
    input {
      padding: 4px 0 0 6px !important;
      box-shadow: none;
      border: none;
    }
  }

  .multiselect__tags {
    border-bottom: unset !important;
  }

  .multiselect__placeholder,
  .multiselect__single {
    padding: 1px 0 0 6px !important;
  }

  .multiselect__placeholder {
    color: transparent;
  }

  // Only if placeholder is to be centered
  // .multiselect__placeholder {
  //   text-align: center;
  //   width: 100%;
  // }

  input {
    box-shadow: none;
    border: none;
  }
}

h1,
h2,
h3 {
  color: $gray-text;
}

.homepage-section-title {
  font-size: 1.2rem;
  font-weight: bold;

  @media screen and (min-width: 768px) {
    font-size: 2.2rem;
  }
}

.text-mini {
  font-size: 0.7rem;
  color: $gray-text;
}

.red-star {
  color: $error-color;
}

// Mixins
@mixin mob-nav-bar {
  display: flex;
  flex-direction: column;
  // flex: 1;
  // position: relative;
  background: $accent-background;
  height: auto;
  position: fixed;
  height: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: $mob-nav-padding-y $mob-nav-padding-x;
    min-height: $mob-nav-height;
    background: $accent-background;
    z-index: 10;
    border-bottom: 1px solid $light-border;

    .logo {
      $size: 32px;
      display: flex;
      align-items: center;
      max-height: $size;
      height: $size;

      img {
        height: 100%;
      }
    }
  }

  .menu {
    $nav-size: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: $accent-background;
    left: 0;
    z-index: 9;
    width: 100%;
    padding: 20px 15px;
    // position: absolute;
    position: fixed;
    top: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
    left: 0;
    right: 0;
    bottom: 0;
    // height: $nav-size;
    // min-height: $nav-size;

    .nav {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin: 0;

      .dropdown {
        @include nav-dropdown;
      }

      li {
        padding: 10px 0;
        margin: 0 10px 5px 0;
        text-align: center;
        cursor: pointer;

        &:hover {
          color: darken($color: white, $amount: 10);
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 10px;

      .auth {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 10px;
      }

      a {
        width: 100%;
      }

      button {
        width: 100%;
      }

      .multiselect-view {
        width: 100%;
        max-width: 100%;
      }
    }

    button {
      margin: 0 auto;
    }
  }

  .mob-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
  }

  a {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #fff;
  }

  .fa-sort-down {
    position: relative;
    top: -5px;
    transition: translate 0.2s ease;

    &.active {
      top: 5px;
      transform: rotate(180deg);
    }
  }

  &.active {
    .top-bar {
      border-bottom: unset;
    }
  }

  @for $i from 0 through 10 {
    ul li:nth-child(#{$i + 1}) {
      transition-delay: 0.1s * $i;
    }
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: all 0.2s;
  }

  .slide-left-enter,
  .slide-left-leave-to {
    transform: translateX(-40px);
    opacity: 0;
  }
}

@mixin footer-bar {
  display: flex;
  flex-direction: column;
  // flex: 1;
  position: relative;
  background: $accent-background;
  height: auto;
}

@mixin full-nav-bar {
  $text-color: rgb(49, 49, 49);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  column-gap: 10px;
  z-index: 9;
  transition: all 0.3s ease;

  @media screen and (min-width: 768px) {
    // overflow: hidden;
    padding: 0 5%;

    // &:hover {
    //   overflow: visible;
    // }
  }

  @media screen and (min-width: 1500px) {
    padding: 0 200px;
  }

  .logo {
    display: flex;
    align-items: center;
    height: 100px;

    img {
      height: 100%;
      width: 100%;
      padding: 20px 0;
    }
  }

  .nav {
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    font-weight: 600;
    gap: 0 20px;
    color: $text-color;
    font-size: 90%;

    @media screen and (min-width: 1500px) {
      font-size: 100%;
    }

    > li {
      // margin-left: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding: 20px 0;

      .dropdown {
        @include nav-dropdown;
      }

      a {
        color: unset;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: lighten($color: $text-color, $amount: 30);
        }
      }

      &:hover {
        .dropdown {
          margin-top: 0;
          display: flex;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  > .right {
    display: flex;
    gap: 10px 15px;
    align-items: center;

    .auth {
      display: flex;
      gap: 10px 15px;

      button {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@mixin nav-dropdown {
  font-size: 85%;
  // display: none;
  flex-direction: column;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    background-color: #fff;
    position: absolute;
    top: 50px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    font-size: inherit;
    // min-width: 100%;
    box-shadow: $global-shadow;
    transition: all 0.5s ease;
  }

  &:hover {
    display: flex;
    opacity: 1;
  }

  li {
    padding: 2px 30px;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
      padding: 10px 30px;
    }
  }
}

@mixin sub-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  padding-top: 20px;

  @media screen and (min-width: 768px) {
    // Used instead of jcc so the modals have the same top val
    padding-top: 9vh;
    // justify-content: center;
  }
}

@mixin forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  background: white;
  padding: 20px 30px;
  box-shadow: 0px 3px 12px rgba(238, 218, 212, 0.54);
  border-radius: 8px;
  position: relative;
  margin-top: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
  user-select: none;
  max-width: 90%;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    padding: 20px 40px;
    width: auto;
  }

  .close-btn {
    position: absolute;
    top: 24px;
    right: 20px;
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .header {
    font-weight: bold;
    font-size: 1.3rem;
    color: $global-text;
  }

  .form-el {
    display: flex;
    flex-direction: column;
    gap: 10px 40px;
  }

  .auth-link {
    font-size: 0.85rem;
  }

  .error-text {
    font-size: 0.9rem;
    text-align: center;
    color: crimson;
  }

  .center-el {
    display: flex;
    flex-direction: column;
    gap: 10px 40px;
    width: 100%;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    > * {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  input,
  textarea,
  .multiselect-view,
  .email-exist-text,
  ::v-deep input {
    width: 100%;

    input {
      // This is only for multiselect view
      max-width: 200px;
    }

    @media screen and (min-width: 768px) {
      width: 250px;
      max-width: 90vw;

      input {
        // This is only for multiselect view
        max-width: unset;
      }
    }
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: transform 0.2s ease;
  }

  .scale-enter,
  .scale-leave-to {
    transform: scale(0.8);
  }
}

@mixin table-outer {
  flex: 1;
  margin-top: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .table-wrap {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex: 1;
  }

  @media screen and (min-width: 768px) {
    margin-top: unset;
    padding: 0 3%;

    .table-wrap {
      margin-top: 20px;
    }

    .pagination {
      margin: auto 0 40px auto;
      padding: 0 10px;
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 0 5%;
  }

  @media screen and (min-width: 1500px) {
    padding: 0 8%;
  }
}

@mixin table-elements {
  .classes-btn {
    color: purple;
    display: flex;
    align-items: center;
    gap: 0 5px;
    text-decoration: none;
    cursor: pointer;
  }

  .group-btn {
    color: rgb(49, 120, 150);
    display: flex;
    align-items: center;
    gap: 0 5px;
    text-decoration: none;
    cursor: pointer;
  }

  .close-btn {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin top-head-table {
  .top-head {
    display: grid;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 0;
    color: gray;
    user-select: none;
    grid-template-areas:
      'left right'
      'center center';

    button {
      > span {
        span {
          display: none;

          @media screen and (min-width: 768px) {
            display: inline-block;
          }
        }
      }
    }

    .left {
      display: flex;
      gap: 10px;
      grid-area: left;
      align-items: center;
      // max-width: 500px;
      overflow: hidden;
      font-size: 0.85em;

      @media screen and (min-width: 768px) {
        font-size: 0.9rem;
      }

      button {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }

      .fa {
        cursor: pointer;
      }
    }

    .center {
      grid-area: center;
      width: 100%;

      @media screen and (min-width: 768px) {
        order: unset;
        width: auto;
      }
    }

    .right {
      grid-area: right;
      margin-left: auto;
      // margin: 0 0 0 auto;
    }

    @media screen and (min-width: 768px) {
      grid-template-areas:
        'left right'
        'center center';
      padding: 10px;
    }

    @media screen and (min-width: 1000px) {
      display: flex;
      grid-template-areas: 'left center center right';
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

      .right {
        margin-left: unset;
      }
    }
  }
}

@mixin slide-left-active {
  .slide-left-enter-active {
    transition: all 0.6s ease;
  }

  // .slide-left-leave-active {
  //   transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  // }
  .slide-left-enter,
  .slide-left-leave-to {
    transform: translateX(-50px);
    opacity: 0;
  }
}

// ## Components
// V-tooltip
// # Slot
.popover-action-slot {
  display: flex;
  flex-direction: column;
  gap: 20px 10px;

  .action {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

// Toastify
.toastify {
  display: flex;

  > span.toast-close {
    margin-left: auto;
  }

  // Overwriting for mobile
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    width: 92% !important;
  }
}

.toastify-success {
  background: linear-gradient(
    90deg,
    rgba(59, 179, 53, 1) 0%,
    rgba(55, 157, 49, 1) 100%
  ) !important;
}

.toastify-warning {
  background: linear-gradient(
    90deg,
    rgb(182, 224, 44) 0%,
    rgb(170, 207, 46) 100%
  ) !important;
}

.toastify-error {
  background: linear-gradient(
    90deg,
    rgba(179, 53, 53, 1) 0%,
    rgba(157, 49, 49, 1) 100%
  ) !important;
}

.toastify-info {
  background: linear-gradient(
    90deg,
    rgb(241, 241, 241) 0%,
    rgb(207, 207, 207) 100%
  ) !important;
  color: black !important;
}

// Vue Date picker
.vdatetime-popup {
  user-select: none;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  margin: 50px auto 0;

  @media screen and (min-width: 768px) {
    margin: 20vh auto 0;
  }
}

.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background-color: $accent-color !important;
}

.vdatetime-year-picker__item--selected,
.vdatetime-month-picker__item--selected,
.vdatetime-time-picker__item--selected,
.vdatetime-popup__actions__button {
  color: $accent-color !important;
}

// Loader spinner {{ progress }}
.progress,
.progress:after {
  $size: 2em;
  border-radius: 50%;
  width: $size;
  height: $size;
}

.progress {
  // Indent: to hide text, overflow: to stop jumping from indent
  $border-size: 0.2em;
  margin: 0 auto;
  font-size: 9px; // Default was 10px
  position: relative;
  text-indent: -9999em;
  overflow: hidden;
  border-top: $border-size solid rgba(255, 255, 255, 0.2);
  border-right: $border-size solid rgba(255, 255, 255, 0.2);
  border-bottom: $border-size solid rgba(255, 255, 255, 0.2);
  border-left: $border-size solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
