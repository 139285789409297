
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






































































































































































































































































#notifications-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;

  ::v-deep {
    .table {
      overflow: hidden;
    }

    .body {
      padding: 4px 0 6px 0;
      overflow: auto;
    }
  }
}
