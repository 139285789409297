
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        



































































































































































































































































































































































































































































#notifications-add {
  @include sub-page;

  .form {
    @include forms;

    .price {
      color: $accent-color;
    }
  }

  ::v-deep {
    .no-tag-close {
      .multiselect__tag-icon {
        display: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .form {
      input,
      .multiselect-view,
      textarea {
        width: 500px;
      }
    }
  }
}
